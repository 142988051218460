import { Component } from '@angular/core';

@Component({
  selector: 'app-health-check',
  template: `<p>Application is healthy</p>`,
  styles: [],
  standalone: true,
})
export class HealthCheckComponent {
  constructor() {}
}
