import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { DttLogoComponent } from '@app/components/common/dtt-logo/dtt-logo.component';
import { AuthService } from '@app/services/auth.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { getAuthToken } from '@app/utils/auth';
import { Key } from '@app/utils/key';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  PfActionButtonComponent,
  PfFormComponent,
  PfFormErrorComponent,
  PfFormLabelWrapperComponent,
  PfFormWrapperComponent,
} from 'pf-ui';

type LoginForm = {
  providerId: FormControl<string | null>;
  sessionCode: FormControl<string | null>;
};

@Component({
  selector: 'app-candidate-login',
  standalone: true,
  imports: [
    CommonModule,
    DttLogoComponent,
    TranslateModule,
    PfActionButtonComponent,
    PfFormComponent,
    PfFormWrapperComponent,
    PfFormErrorComponent,
    PfFormLabelWrapperComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class CandidateLoginComponent extends AbstractFormComponent {
  form!: FormGroup<LoginForm>;

  constructor(
    protected formBuilder: FormBuilder,
    private userExamSessionService: UserExamSessionService,
    private authService: AuthService,
  ) {
    super();
    this.form = this.formBuilder.group<LoginForm>({
      providerId: formBuilder.control('', [Validators.required]),
      sessionCode: formBuilder.control('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
    });
  }

  get controls(): LoginForm {
    return this.form.controls;
  }

  markAllAsDirty(form: FormGroup<LoginForm>): void {
    Object.keys(form.controls).forEach((key: string) => {
      const control = form.get(key);
      if (control instanceof FormControl) {
        control.markAsDirty();
      }
    });
  }

  onConnect(): void {
    this.form.reset(this.form.value);

    this.form.markAllAsTouched();
    this.markAllAsDirty(this.form);

    if (!this.form.dirty || !this.form.valid) return;

    const { providerId, sessionCode } = this.form.value;

    if (providerId == null) return;
    if (sessionCode == null) return;

    this.authService.setCandidateToken(getAuthToken(providerId, sessionCode));

    this.userExamSessionService.checkAuthentication().subscribe({
      next: () => this.handleAuthenticationSuccess(),
      error: () => {
        this.handleAuthenticationError({ loginFailed: true });
      },
    });
  }

  isSingleDigitRegex(charCode: string): boolean {
    const singleDigitRegex = /^\d$/;
    const regexMatch = RegExp(singleDigitRegex).exec(charCode);

    if (regexMatch === null) {
      return false;
    }

    return regexMatch[0] !== null;
  }

  // Gestionnaire d'événements pour empêcher la saisie de lettres
  preventNonNumericInput(event: KeyboardEvent): void {
    const charCode = event.key;

    if (charCode === 'Enter') this.onConnect();

    const isSelectAllEvent = charCode === Key.A && event.ctrlKey;
    const allowedKeyEvent =
      this.isSingleDigitRegex(charCode) ||
      charCode === Key.Backspace ||
      isSelectAllEvent ||
      charCode === Key.ArrowLeft ||
      charCode === Key.ArrowRight;

    if (!allowedKeyEvent) {
      event.preventDefault();
    }
  }

  handleAuthenticationError(
    error: ValidationErrors = { loginFailed: true },
  ): void {
    this.form.controls.sessionCode.setErrors(error);
    this.form.controls.providerId.setErrors(error);
  }

  handleAuthenticationSuccess(): void {
    this.userExamSessionService.login();
  }
}
