import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/services/auth.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { CandidateRoute } from '@app/utils/routes';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

export const CandidateAuthGuard = (): Observable<boolean> => {
  const userExamSessionService = inject(UserExamSessionService);
  const authService = inject(AuthService);
  const router = inject(Router);

  const checkCandidateCookie = (): Observable<boolean> => {
    const isToken = authService.checkCandidateToken();

    if (!isToken) return of(false);

    return userExamSessionService.checkAuthentication();
  };

  return userExamSessionService.isAuthenticated$.pipe(
    take(1),
    switchMap((isAuthenticated) => {
      if (isAuthenticated) return of(true);

      return checkCandidateCookie();
    }),
    catchError(() => {
      return router.navigate([CandidateRoute.Login]);
    }),
    switchMap((isCandidateCookieValid) => {
      if (isCandidateCookieValid) return of(true);

      router.navigate([CandidateRoute.Login]);
      return of(false);
    }),
  );
};
