<div class="grid h-screen">
  <div class="col col-6 flex flex-column left-panel">
    <app-dtt-logo class="fixed"></app-dtt-logo>
    <div class="flex flex-column mb-auto mt-auto">
      <div class="title">Bienvenue</div>
      <p>
        Plateforme numérique dédiée au passage des examens pour les Transports
        Terrestres.
      </p>
    </div>
  </div>
  <pf-form [formGroup]="form" class="right-panel h-full col col-6">
    <div class="flex flex-column w-full">
      <h1>Connexion</h1>
      <p class="mt-0 mb-5">
        Connecte-toi à ta session d'examen à l'aide de ton identifiant candidat
        et du code de la session donné par le surveillant.
      </p>
      <div class="field flex flex-column">
        <pf-form-label-wrapper
          class="m-0"
          [showErrors]="showErrors(form, 'providerId')"
        >
          <ng-template #label>
            <label class="font-normal" for="providerId"
              >Identifiant candidat</label
            >
          </ng-template>
          <ng-template #errors>
            <pf-form-error [label]="getError(form, 'providerId')"></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <input
          type="text"
          id="providerId"
          class="p-inputtext w-full"
          placeholder="aBcD"
          formControlName="providerId"
        />
      </div>
      <div class="field flex flex-column">
        <pf-form-label-wrapper
          class="m-0"
          [showErrors]="showErrors(form, 'sessionCode')"
        >
          <ng-template #label>
            <label class="font-normal" for="sessionCode"
              >Code de la session</label
            >
          </ng-template>
          <ng-template #errors>
            <pf-form-error [label]="getError(form, 'sessionCode')"></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <input
          id="sessionCode"
          class="p-inputtext w-full"
          placeholder="1234"
          (keydown)="preventNonNumericInput($event)"
          formControlName="sessionCode"
        />
      </div>
      <pf-action-button
        class="align-self-center mt-3 w-max"
        label="Se connecter"
        (buttonClicked)="onConnect()"
      ></pf-action-button>
    </div>
  </pf-form>
</div>
