import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { hasAnyAuthorities } from '@models/user/agent-detail.model';
import { AgentService } from '@services/agent.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  agent = this.agentService.signalCurrent;

  constructor(
    protected override readonly router: Router,
    protected agentService: AgentService,
    protected readonly keycloak: KeycloakService,
    protected messageService: MessageService,
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    await firstValueFrom(this.agentService.getMe());

    const permissions = route.routeConfig?.data?.['permissions'] ?? [];
    const isUnauthorized = !hasAnyAuthorities(this.agent(), permissions);

    if (permissions.length > 0 && isUnauthorized) {
      const err = { status: 403 };
      await firstValueFrom(this.agentService.handleErrors(err));
      return false;
    }

    return this.authenticated;
  }
}
