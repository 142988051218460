<div class="pl-8 pr-8 h-full">
  <div class="flex flex-column h-full">
    @if (
      !currentStep() ||
      (!isExamScreenDisplayed() && currentStep() === ExamStep.EXAM)
    ) {
      <app-spinner class="spinner-container"></app-spinner>
    } @else {
      <h2>{{ currentStepPageTitle }}</h2>
      <div class="flex-grow-1 flex flex-column">
        <pf-form-wrapper [classList]="'w-full border-round-md'">
          @if (isStepperDisplayed()) {
            <ng-template #steps>
              <pf-steps
                [currentStep]="formManager.currentStep + 1"
                [steps]="stepperSteps"
              ></pf-steps>
            </ng-template>
          }
        </pf-form-wrapper>
        <div class="flex-grow-1">
          <!-- STEP 1 :  PERSONAL INFORMATION -->
          @defer (when currentStep() === ExamStep.PERSONAL_INFORMATION) {
            <app-personal-information
              class="w-full"
              *ngIf="currentStep() === ExamStep.PERSONAL_INFORMATION"
              [goToNextStep]="goToTrainingWaitingExplanation"
              [userExamSession]="userExamSession()"
              [examSession]="examSession()"
            >
            </app-personal-information>
          }
          <!-- STEP 2 : WAITING FOR ADMIN STARTING VALIDATION (TO START TRAINING EXPLANATION) -->
          @defer (when currentStep() === ExamStep.WAITING_TRAINING) {
            <app-waiting-page
              class="w-full"
              *ngIf="currentStep() === ExamStep.WAITING_TRAINING"
              [categoryId]="userExamSession()?.serie?.category?.id"
              [examStep]="ExamStep.WAITING_TRAINING"
              [waitingMessage]="getWaitingMessage()"
            >
            </app-waiting-page>
          }
          <!-- STEP 3: EXPLANATION  (actuellement hors routage et hors step!!-->
          @defer (when currentStep() === ExamStep.EXPLANATION_TRAINING) {
            <app-explanation
              *ngIf="currentStep() === ExamStep.EXPLANATION_TRAINING"
              (enableTrainingStep)="goToTrainingStep()"
            >
            </app-explanation>
          }
          <!-- STEP 4 : TRAINING QUESTIONS -->
          @defer (when currentStep() === ExamStep.TRAINING_QUESTIONS) {
            <app-candidate-questions
              *ngIf="currentStep() === ExamStep.TRAINING_QUESTIONS"
            />
          }
          <!-- STEP 5 : TRAINING CORRECTIONS -->
          @defer (when currentStep() === ExamStep.TRAINING_CORRECTION) {
            <app-candidate-training-correction
              *ngIf="currentStep() === ExamStep.TRAINING_CORRECTION"
            >
            </app-candidate-training-correction>
          }
          <!-- STEP 6 : WAITING OFFICIAL EXAM -->
          @defer (when currentStep() === ExamStep.WAITING_EXAM) {
            <app-waiting-page
              class="w-full"
              *ngIf="currentStep() === ExamStep.WAITING_EXAM"
              [examId]="examSession()?.id"
              [examStep]="ExamStep.WAITING_EXAM"
              [providerId]="providerId"
              [waitingMessage]="getWaitingMessage()"
            >
            </app-waiting-page>
          }
          <!-- STEP 7 : OFFICIAL EXAM -->
          @defer (
            when currentStep() === ExamStep.EXAM && isExamScreenDisplayed()
          ) {
            <app-candidate-questions
              [isTrainingQuestion]="false"
              *ngIf="
                currentStep() === ExamStep.EXAM &&
                isExamScreenDisplayed()
              "
            >
            </app-candidate-questions>
          }
          <!-- STEP 8 : SESSION END -->
          @defer (when currentStep() === ExamStep.SESSION_END) {
            <app-waiting-page
              class="w-full"
              *ngIf="currentStep() === ExamStep.SESSION_END"
              [waitingMessage]="getWaitingMessage()"
            >
            </app-waiting-page>
          }
          <!-- STEP 9 : RESULTS -->
          @defer (when currentStep() === ExamStep.RESULTS) {
            <app-candidate-results *ngIf="currentStep() === ExamStep.RESULTS" />
          }
        </div>
      </div>
    }
  </div>
</div>
